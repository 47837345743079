
/* Report Action Types */
export const RO_ORDERED = 1;
export const RO_BEGIN_DATA_RECONCILIATION = 2;
export const RO_DATA_RECONCILIATION_QUESTIONS = 3;
export const RO_DATA_RECONCILIATION_QUESTIONS_RESOLVED = 4;
export const RO_DATA_RECONCILIATION_REVIEW_COMPLETED = 5;
export const RO_BEGIN_PROPERTY_ANALYSIS = 6;
export const RO_PROPERTY_ANALYSIS_QUESTIONS = 7;
export const RO_PROPERTY_ANALYSIS_QUESTIONS_RESOLVED = 8;
export const RO_PROPERTY_ANALYSIS_COMPLETED = 9;
export const RO_ANALYSIS_REVIEW_INITIATED = 10;
export const RO_PROPERTY_ANALYSIS_REVIEWED = 11;
export const RO_REPORT_FINALIZED = 12;
export const RO_REPORT_DELIVERED = 13;
export const RO_REPORT_CANCELLED = 14;
export const RO_CLIENT_INQUIRY = 15;
export const RO_CLIENT_INQUIRY_RESPONSE = 16;
export const RO_CLIENT_INQUIRY_RESOLVED = 17;
export const RO_REVISION_PREPARED = 18;
export const RO_REVISION_FINALIZED = 19;
export const RO_REVISION_DELIVERED = 20;
export const RO_ANALYSIS_UNASSIGNED = 21;
export const RO_ANALYSIS_REVIEW_UNASSIGNED = 22;
export const RO_ANALYSIS_REVIEW_QUESTIONS = 23;
export const RO_ANALYSIS_REVIEW_QUESTIONS_RESOLVED = 24;
export const RO_ON_HOLD = 25;
export const RO_SNAG_RELEASED = 26;
export const RO_ANALYSIS_REVIEW_EDITS_REQUESTED = 27;
export const RO_ANALYSIS_REVIEW_EDITS_MADE = 28;
export const RO_PROPERTY_ANALYSIS_EDITS_REQUESTED = 29;
export const RO_PROPERTY_ANALYSIS_EDITS_MADE = 30;
export const RO_HOLD_RELEASED = 31;
export const RO_REVISION_REQUESTED = 32;
export const RO_BUDGET_UPDATED = 33;
export const RO_DISPUTE_INITIATED = 34;
export const RO_NEW_SPECS = 35;
export const RO_MARKET_UPDATE = 36;
export const RO_REPORT_TYPE_CHANGED = 37;
export const RO_TRANSFER_TO_NEW_CLIENT = 38;
export const RO_TRANSFERED = 39;

/* Inspection Action Types */
export const IO_ORDERED = 1;
export const IO_ACCEPTED = 2;
export const IO_CONTACT_ATTEMPTED = 3;
export const IO_CONTACT_MADE = 4;
export const IO_SCHEDULED = 5;
export const IO_DENIED = 6;
export const IO_QUESTIONS = 7;
export const IO_COMPLETED = 9;
export const IO_REVISED = 10;
export const IO_RECEIVED = 11;
export const IO_CANCELLED = 12;
export const IO_CHECK_COVERAGE = 13;
export const IO_RESCHEDULED = 14;
export const IO_QC = 15;
export const IO_UPLOADING = 16;
export const IO_ON_HOLD = 17;
export const IO_DISMISSED = 18;

/* Inspection Types */
export const IT_DRAW = 8;

/* Action Types */
export const AT_REPORT = 1;
export const AT_INSPECTION = 2;

/* Discrepancy Statuses */
export const DS_CREATED = 1;
export const DS_INPROGRESS = 2;
export const DS_QUESTION = 3;
export const DS_ANSWERED = 4;
export const DS_COMPLETED = 5;
export const DS_STUMPED = 6;
export const DS_INCOMPLETE_DATA = 7;
export const DS_EXPIRED = 8;

/*Inspection Vendors */
export const IV_PROXYPICS = 1;
export const IV_SPECTRUM = 2;

/* Report Types*/
export const RT_PROP_VALUATION = 1;
export const RT_RENO_ARV = 2;
export const RT_RENO_STRATEGY = 3;
export const RT_REO_STRATEGY = 4;
export const RT_PLTV = 5;
export const RT_BPO_REPLACE = 6;
export const RT_EVAL = 7;

/* Payment Statuses */
export const PAY_PAID = 1;
export const PAY_PENDING_INVOICE = 2;
export const PAY_VOIDED = 3;
export const PAY_UNCOLLECTABLE = 4;
export const PAY_UNPAID = 5;
export const PAY_REFUNDED = 6;
export const PAY_PENDING_REFUND = 7;
export const PAY_UNPAID_REFUND = 8;
export const PAY_EXEMPTED = 9;

/* Inspection Action Types Statuses */
export const IAT_ORDERED = 1;
export const IAT_ACCEPTED = 2;
export const IAT_CONTACT_ATTEMPTED = 3;
export const IAT_CONTACT_MADE = 4;
export const IAT_SCHEDULED = 5;
export const IAT_DENIED = 6;
export const IAT_QUESTIONS = 7;
export const IAT_COMPLETED = 9;
export const IAT_REVISED = 10;
export const IAT_RECEIVED = 11;
export const IAT_CANCELLED = 12;
export const IAT_FOLLOW_UP = 13;
export const IAT_RESCHEDULED = 14;
export const IAT_QC = 15;
export const IAT_UPLOADING = 16;
export const IAT_ON_HOLD = 17;
export const IAT_DISMISSED = 18;


// Property Files Types
export const PFT_INSPECTION = 1;
export const PFT_APPRAISAL = 2;
export const PFT_BUDGET = 3;
export const PFT_PHOTOS = 4;
export const PFT_OTHER = 5;
export const PFT_COVER_PHOTO = 6;
export const PFT_INTAKE_FORM = 7;
export const PFT_ARCHITECTURAL_PLANS = 8;
export const PFT_CONTRACTS = 9;
export const PFT_MARKETING_PACKET = 10;
export const PFT_VALUATION_DISPUTE = 11;
export const PFT_FLOOD = 12;
export const PFT_VIDEO = 13;
export const PFT_PLAT_MAPS_OR_AERIAL_MAPS = 14;

export const ROLE_ADMINISTRATOR = 1;
export const ROLE_MODERATOR = 3;
export const ROLE_SALES_REP = 5;
export const ROLE_DATA_RECONCILIATION = 9;
export const ROLE_ANALYST = 10;
export const ROLE_REVIEWER = 11;
export const ROLE_LOAN_OFFICER = 15;
export const ROLE_DRV_ADMIN = 16;
export const ROLE_LOAN_PROCESSOR = 19;
export const ROLE_PROCESSOR = 17;
export const ROLE_ACCOUNT_REP = 20;
export const ROLE_INSPECTOR = 25;

// Subject Property Condition
export const TEAR_DOWN_NEW_BUILD = 1;
export const VERY_POOR = 2;
export const POOR = 3;
export const MODERATE = 4;
export const MAINTAINED = 5;
export const PARTIALLY_REMODELED = 6;
export const FULLY_REMODELED = 7;
export const NEW_BUILT = 8;

// Quality Grade
export const E_GRADE = "E";
export const D_GRADE = "D";
export const C_AMD_C_MINUS_GRADE = "C";
export const C_PLUS_GRADE = "C+";
export const B_MINUS_GRADE = "B-";
export const B_GRADE = "B";
export const B_PLUS_GRADE = "B-";
export const A_MINUS_GRADE = "A-";
export const A_GRADE = "A";
export const A_PLUS_GRADE = "A+";

// Quality Grade Number
export const E = 1;
export const D = 2;
export const C_OR_C_MINUS = 3;
export const C_PLUS = 4;
export const B_MINUS = 5;
export const B = 6;
export const B_PLUS = 7;
export const A_MINUS = 8;
export const A = 9;
export const A_PLUS = 10;


export const DASHBOARD_ADMIN_REPORT_FILTER = [
    {
        name: 'This Month',
        value: 'thisMonth'
    },
    {
        name: 'Last Month',
        value: 'lastMonth'
    },
    {
        name: 'This Quarter',
        value: 'thisQuarter'
    },
    {
        name: 'Last Quarter',
        value: 'lastQuarter'
    },
    {
        name: 'This Year',
        value: 'thisYear'
    },
    {
        name: 'Last Year',
        value: 'lastYear'
    },
    {
        name: 'Custom',
        value: 'custom'
    },
];

export const TIME_TRACKING_DASHBOARD_STATUS_FILTER = [
    {
        name: 'Analyze',
        value: 'analyze'
    },
    {
        name: 'Review',
        value: 'review'
    },
    {
        name: 'Finalize',
        value: 'finalize'
    },
    {
        name: 'Revise',
        value: 'revise'
    }
];

export const TIME_TRACKING_DASHBOARD_SLICES_FILTER = [
    {
        name: 'Duration',
        value: 'duration'
    },
    {
        name: 'Difficulty',
        value: 'difficulty'
    },
    {
        name: 'Report Type',
        value: 'reportType'
    },
    {
        name: 'Months',
        value: 'months'
    }
];

export const TIME_TRACKING_DASHBOARD_METRICS_FILTER = [
    {
        name: 'Time',
        value: 'time'
    },
    {
        name: 'Grade',
        value: 'grade'
    },
    {
        name: 'Count',
        value: 'count'
    },
    {
        name: '% of Files',
        value: 'percentOfFiles'
    },
    {
        name: 'Compensation',
        value: 'compensation'
    },
    {
        name: '$/Hr',
        value: 'totalCompensationPerHour'
    }
];


export const DASHBOARD_ANALYST_REPORT_FILTER = [
    {
        name: 'Days',
        value: 'days'
    },
    {
        name: 'Week',
        value: 'week'
    },
    {
        name: 'Month',
        value: 'month'
    },
];

export const USER_LEVELS_ANALYST = [
    {
        name: 'Analyst Level 0',
        value: 0,
    },
    {
        name: 'Analyst Level 1',
        value: 1,
    },
    {
        name: 'Analyst Level 2',
        value: 2,
    },
    {
        name: 'Analyst Level 3',
        value: 3,
    },
    {
        name: 'Analyst Level 4',
        value: 4,
    }
];

export const USER_LEVELS_REVIEWER = [
    {
        name: 'Reviewer Level 0',
        value: 0,
    },
    {
        name: 'Reviewer Level 1',
        value: 1,
    },
    {
        name: 'Reviewer Level 2',
        value: 2,
    },
    {
        name: 'Reviewer Level 3',
        value: 3,
    },
    {
        name: 'Reviewer Level 4',
        value: 4,
    }
];

export const USER_CONSTRUCTION_LEVELS = [
    {
        name: 'Construction Level 1',
        value: 1,
    },
    {
        name: 'Construction Level 2',
        value: 2,
    },
    {
        name: 'Construction Level 3',
        value: 3,
    },
    {
        name: 'Construction Level 4',
        value: 4,
    }
];

export const BT_SIZE_PER_PAGE_LIST = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "100", value: 100 },
]

export const TRAINING_PROGRESS_STATUS = {
    INPROGRESS: 'Inprogress',
    COMPLETED: "Completed"
}

export const TRAINING_COMPONENT_TYPES = {
    OVERVIEW_TEXT: 1,
    OVERVIEW_VIDEO: 2,
    VIDEOS: 3,
    WRITTEN_MATERIALS: 4,
    EXERCISES: 5,
    QUIZZES: 6,
    TASKS: 7,
}

export const ORDER_TYPES = {
    EVAL: 'eval',
    EPO: 'epo'
};
